$c-primary: #ff6200;
$c-secandary: #525199;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'ING Me', sans-serif;
$font-family-heading: 'ING Me', sans-serif;

      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-Bold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-BoldItalic.ttf') format('truetype');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-Bold_1.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-Italic_1.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe_1.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-BoldItalic_1.ttf') format('truetype');
        font-weight: 600;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.Select {
  position: relative;
  .hasError {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 3px;
    color: $c-danger;
  }
}

.leftPadding30Perc {
  padding: 0.5rem 0.7rem 0.5rem 40% !important;
}

:export {
  primary: var(--primary);
  secondary: var(--secondary);
  primary25: '#fff';
  primary50: lighten($c-secandary, 45%);
  gray: $c-grey;
  bodyGray: #fff;
}
