$c-primary: #ff6200;
$c-secandary: #525199;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'ING Me', sans-serif;
$font-family-heading: 'ING Me', sans-serif;

      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-Bold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-BoldItalic.ttf') format('truetype');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-Bold_1.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-Italic_1.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe_1.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ING Me';
        src: url('/assets-mobile/fonts/INGMe-BoldItalic_1.ttf') format('truetype');
        font-weight: 600;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.formInputRow {
  position: relative;

  & > span {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 35%;
      font-weight: bold;
      font-size: 1rem; //16
      line-height: 1.2;
      z-index: 1;
      height: 3.75rem; //60
      display: flex;
      align-items: center;
    }
  }
}

.control {
  background-color: white;
  border-bottom: 1px solid rgba(25, 25, 25, 0.32);
  border-radius: 0;
  padding-left: 35%;
}
